<template>
  <div id="app" class="container-fluid m-0 p-0">
    <!-- HEADER -->
    <nav class="navbar navbar-expand-md navbar-light bg-light p-0 px-2">
      <router-link to="/" class="navbar-brand nav-link">
        <img src="favicon.png" width="30" height="30" class="d-inline-block align-top" alt="" />
        Pathogens3D
        <span class="sr-only">(home)</span>
      </router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler1"
        aria-controls="navbarToggler1" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler1">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav">
          <span class="navbar-text text-center">
            <a href="http://godziklab.org/" target="_blank" title="Godzik Lab" class="side-margin">
              <img src="images/lab_logo.png" alt="Godzik Lab logo" height="30" />
            </a>
            <a href="http://csbid.org/" target="_blank" title="Center for Structural Biology of Infectious Diseases"
              class="side-margin">
              <img src="images/csbid.png" alt="Center for Structural Biology of Infectious Diseases" height="30" />
            </a>

            <a href="https://medschool.ucr.edu/" target="_blank"
              title="University of California, Riverside School of Medicine" class="side-margin">
              <img src="images/ucr3.png" alt="UCR logo" height="30" />
            </a>
          </span>
        </ul>
      </div>
    </nav>
    <nav class="navbar sticky-top navbar-expand-md navbar-dark bg-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
        aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav mr-auto mt-1 mt-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">
              Home
              <span class="sr-only">(Home)</span>
            </router-link>
          </li>

          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownHelpLink" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              About
            </a>
            <div class="dropdown-menu bg-dark navbar-dark" aria-labelledby="navbarDropdownHelpLink">
              <router-link to="/help" class="nav-link">
                Help
                <span class="sr-only">(help)</span>
              </router-link>
             
              <router-link to="/acknowledgements" class="nav-link">
                Acknowledgements
                <span class="sr-only">(acknowledgements)</span>
              </router-link>
            </div>
          </li> -->
        </ul>

      </div>
    </nav>
    <nav class="navbar sticky-top navbar-expand-md navbar-light bg-light border-bottom"
      v-if="$route.params.family && $route.params.species && $route.params.segment">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler2"
        aria-controls="navbarToggler2" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler2">
        <ul class="navbar-nav mr-auto mt-1 mt-md-1">
          <li class="nav-item">
            <strong class="nav-link">{{ this.$route.params.species.toUpperCase() }} 
              <span v-if="this.$route.params.segment.toUpperCase() === 'GENOME'"> whole genome</span>
              <span v-else> segment {{ this.$route.params.segment.toUpperCase() }}</span>:
            </strong></li>
          <li class="nav-item" v-if="$route.params.species && $route.params.segment">
            <router-link :to="appendCurrentRouteParams('/3dproteomeviewer')" class="nav-link">
               3D proteome viewer
              <span class="sr-only">(3D proteome viewer)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="appendCurrentRouteParams('/diversity')" class="nav-link">
              Genomic diversity
              <span class="sr-only">(Genomic diversity)</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!-- CONTENT -->
    <div class="main no-padding">
      <hr />
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data: function () {
    return {};
  },
  methods: {
    appendCurrentRouteParams(target) {
      const params = this.$route.params;
      let fam = params.family || 'bunyaviruses';
      let sp = params.species || 'lasv';
      let sg = params.segment || 's';
      // TODO make sure plain urls work
      return `${target}/${fam.toLowerCase()}/${sp.toLowerCase()}/${sg.toLowerCase()}/`;
    }
  }
};
</script>
<style>
.no-padding {
  padding: 0px !important;
}

.nav-link {
  padding-left: 0px;
  padding-right: 8px;
}

.navbar-dark>.router-link-active {
  color: white;
}
</style>