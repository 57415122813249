import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "proteomeviewer" */ '../views/Home.vue')
  },
  {
    path: '/3dproteomeviewer/:family/:species/:segment',
    name: '3DProteomeViewer',
    component: () => import(/* webpackChunkName: "proteomeviewer" */ '../views/GenomeBrowser.vue')
  },
  {
    path: '/diversity/:family/:species/:segment',
    name: 'GenomicDiversity',
    component: () => import(/* webpackChunkName: "mutesxplorer" */ '../views/GenomicDiversity.vue')
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/acknowledgements',
    name: 'Acknowledgements',
    component: () => import(/* webpackChunkName: "acknowledgement" */ '../views/Acknowledgement.vue')
  },
]

const router = new VueRouter({
  routes: routes,
  base: "/",
  // mode:'history'
})

export default router
